<template>
    <social-share :config="shareConfig" v-if="info.id" />
</template>
<script>
import SocialShare from 'vue-social-share';
export default {
    name: 'share',
    props: {
        isBrand: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        local: {
            type: String,
            default: 'zh'
        }
    },
    components: {
        SocialShare
    },
    computed: {
        shareConfig() {
            if (this.isBrand) {
                return {
                    url:
                        'https://www.imttech.cn/index/brandDetail?id=' +
                        this.info.id +
                        (this.isChinese ? '' : '&local=en'),
                    title: this.getName(this.info, ['chCompanyName', 'enCompanyName']), // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
                    description: this.info.introduction, // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
                    image: this.info.logo, // 图片, 默认取网页中第一个img标签
                    sites: ['qq', 'weibo', 'wechat', 'facebook', 'twitter'], // 启用的站点
                    disabled: [], // 禁用的站点
                    wechatQrcodeTitle: this.$t('wei-xin-sao-yi-sao-fen-xiang', this.local),
                    wechatQrcodeHelper:
                        '<p>' +
                        this.$t('wei-xin-li-dian-fa-xian-sao-yi-xia', this.local) +
                        '</p><p>' +
                        this.$t('er-wei-ma-bian-ke-jiang-ben-wen-fen-xiang-zhi-peng-you-quan', this.local) +
                        '</p>'
                };
            } else {
                return {
                    url:
                        'https://www.imttech.cn/index/productDetail?id=' +
                        this.info.id +
                        (this.isChinese ? '' : '&local=en'),
                    title: this.getName(this.info), // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
                    description: this.getName(this.info, ['model', 'enModel']), // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
                    image: this.getList(this.info.img), // 图片, 默认取网页中第一个img标签
                    sites: ['qq', 'weibo', 'wechat', 'facebook', 'twitter'], // 启用的站点
                    disabled: [], // 禁用的站点
                    wechatQrcodeTitle: this.$t('wei-xin-sao-yi-sao-fen-xiang', this.local),
                    wechatQrcodeHelper:
                        '<p>' +
                        this.$t('wei-xin-li-dian-fa-xian-sao-yi-xia', this.local) +
                        '</p><p>' +
                        this.$t('er-wei-ma-bian-ke-jiang-ben-wen-fen-xiang-zhi-peng-you-quan', this.local) +
                        '</p>'
                };
            }
        }
    }
};
</script>
